import * as React from "react";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import Header from "../components/Navbar/Header/Header";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";
import Project from "../components/Project/Project";
import EmptySlider from "../components/Sliders/EmptySlider/EmptySlider";

import "../css/pages/index.scss";

const ProjectTemplate = ({ data }) => {
  const projectData = data.project;
  const imageHost = data.site.siteMetadata.imageHost;

  return (
    <>
      <Seo
        title={projectData.edges[0].node.seo_title}
        description={projectData.edges[0].node.seo_description}
      ></Seo>
      <Header />
      <main>
        <EmptySlider>
          {projectData &&
            projectData.edges.map((item, index) => (
              <Project
                key={item.node.id}
                headingText={item.node.nav_title}
                projectTitle={item.node.project_title}
                projectDescription={item.node.description.data.description}
                topImg1={item.node.top_left_image.localFile.url}
                topImg2={item.node.top_right_image.localFile.url}
                rightImg1={item.node.side_top_image.localFile.url}
                rightImg2={item.node.side_bottom_image.localFile.url}
                imageHost={imageHost}
              ></Project>
            ))}
        </EmptySlider>

        <Contact oneMap={true}></Contact>
      </main>
      <Footer />
    </>
  );
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectTemplate($slug: String!) {
    site: site {
      siteMetadata {
        imageHost
      }
    }
    project: allStrapiProject(filter: { project_groups: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          id
          slug
          description {
            data {
              description
            }
          }
          nav_title
          project_title
          seo_description
          seo_title
          side_bottom_image {
            localFile {
              url
            }
          }
          side_top_image {
            localFile {
              url
            }
          }
          top_left_image {
            localFile {
              url
            }
          }
          top_right_image {
            localFile {
              url
            }
          }
        }
      }
    }
  }
`;
