import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { modifyMdImageUrls } from "../../utils";

import ImageBlock from "../Blocks/ImageBlock/ImageBlock";
import BlocksContainer from "../BlocksContainer/BlocksContainer";

import "../../css/components/Project.scss";

const Project = ({
  headingText,
  projectTitle,
  projectDescription,
  topImg1,
  topImg2,
  rightImg1,
  rightImg2,
  logo,
  imageHost
}) => {
  const boxHeight = "650px";
  const boxHeightRectangle = "360px";
    const projectDescriptionWithImageUrlFixed = modifyMdImageUrls(projectDescription, imageHost);

  return (
    <div>
      <Container fluid className="project-container">
        <Row>
          <Col md={12} lg={6} className="px-0">
            <ImageBlock headingText={headingText} hasBackArrow={true} textColor={"white"} backgroundImage={topImg1} boxHeight={boxHeight}></ImageBlock>
          </Col>
          <Col md={12} lg={6} className="px-0">
            <ImageBlock backgroundImage={topImg2} boxHeight={boxHeight} hasOverlay={false} boxLogo={logo}></ImageBlock>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={2}>
            <ul className="no-list-style pt-4">
              <li>
                <Link to="/energy">ENERGIA</Link>
              </li>
              <li>
                <Link to="/smart-city">VÁROSFEJLESZTÉS</Link>
              </li>
              <li>
                <Link to="/smart-city">DIGITÁLIS TERMÉKEK</Link>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={12} lg={4} className="left-block pl-0">
            <div className="content">
              <h2>{projectTitle}</h2>
              <div>{<ReactMarkdown rehypePlugins={[rehypeRaw]} children={projectDescriptionWithImageUrlFixed} />}</div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="right-block p-0">
            <BlocksContainer>
              <ImageBlock backgroundImage={rightImg1} textColor={"white"} boxHeight={boxHeightRectangle} hasOverlay={false}></ImageBlock>
              <ImageBlock backgroundImage={rightImg2} textColor={"white"} boxHeight={boxHeightRectangle} hasOverlay={false}></ImageBlock>
            </BlocksContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Project;
